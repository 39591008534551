import React from 'react';
import './HormonalHealth.css';
import endocrine from './endocrine.jpg';

export default class HormonalHealth extends React.Component{
    render() {
        return (
            <div className={'hormonal-health-wrapper-div'}>
                <div className={'hormonal-health-title'}>
                    {`Hormonal Health`}
                </div>
                <div className={'hormonal-health-subtitle'}>
                    What are Hormones?
                </div>
                <div className={'hormonal-health-paragraph'}>
                    <b>Hormones</b> are your body's chemical messengers. They travel in your bloodstream to tissues or organs to help them do their work. The glands of the endocrine system send out <b>hormones</b> that tell each part of your body what work to do, when to do it, and for how long. <b>Hormones</b> are vital to your <b>health</b> and well-being. 
                </div>
                <div className={'hormonal-health-paragraph'}>
                    The <b>endocrine system</b> is made up of eight major <b>endocrine glands</b> scattered throughout the body.  They are still considered to be one system because they have similar functions, similar mechanisms of influence, and <b>many</b> important interrelationships.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    <b style={{textDecoration:'underline'}}>Note:</b> There is another type of gland called an <b>exocrine gland</b> (e.g. <b>sweat glands</b>, lymph nodes). These are not considered part of the endocrine system as they do not produce hormones and they release their product through a duct.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Simply put, the endocrine system is a network of glands that secrete chemicals called hormones to help your body function properly. Hormones are chemical signals that coordinate a range of bodily functions. The endocrine system helps control the following processes and systems:
                    <ul>
                        <li className={'hormonal-health-bullet'}>Growth and development</li>
                        <li className={'hormonal-health-bullet'}>Homeostasis (the internal balance of body systems)</li>
                        <li className={'hormonal-health-bullet'}>Metabolism (body energy levels)</li>
                        <li className={'hormonal-health-bullet'}>Reproduction</li>
                        <li className={'hormonal-health-bullet'}>Response to stimuli (stress and/or injury)</li> 
                    </ul>
                </div>
                <div className={'hormonal-health-subtitle'}>
                    That brings us to the Endocrine Network.
                </div>
                <div className={'hormonal-health-sub-subtitle'}>
                    The Endocrine Network
                </div>
                <div className={'hormonal-health-paragraph'}>
                    The endocrine system completes these tasks through its network of glands, which are small but highly important organs that produce, store, and secrete hormones. The glands of the endocrine system are:
                    <ul>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-hypothalamus'}>Hypothalamus</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-pineal-gland'}>Pineal Gland</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-pituitary-gland'}>Pituitary Gland</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-thyroid'}>Thyroid</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-parathyroid'}>Parathyroid</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-thymus'}>Thymus</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-adrenal-glands'}>Adrenal</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-pancreas'}>Pancreas</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-ovaries'}>Ovaries</a></li>
                        <li className={'hormonal-health-bullet'}><a href={'https://www.endocrineweb.com/endocrinology/overview-testes'}>Testes</a></li> 
                    </ul>
                </div>
                <div className={'hormonal-health-paragraph'}>
                    These glands produce different types of hormones that evoke a specific response in other cells, tissues, and/or organs located throughout the body. The hormones reach these faraway targets using the blood stream. Like the nervous system, the endocrine system is one of your body’s main communicators. But instead of using nerves to transmit information, the endocrine system uses blood vessels to deliver hormones to cells.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    The diagram below from Live Science will help you visualize this core system.
                </div>
                <div className={'container'}>
                    <img src={endocrine}  alt="..."/>
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Next step will be to understand the importance of maintaining a healthy hormonal system.
                </div>
                <div className={'hormonal-health-subtitle'}>
                    Why is it so important to keep healthy hormonal health?
                </div>
                <div className={'hormonal-health-paragraph'}>
                    In a nutshell, a healthy hormone balance protects your OS. Yes, you guessed it right! Healthy hormonal balance is key to the Operating System of the entire body.
Hormones keep your body running smoothly. They help fight harmful bacteria and viruses and promote an overall clean function, which includes feeling good and a more youthful appearance. Hormones have profound effects on your mental, physical and emotional health.
                </div>
                <div className={'hormonal-health-sub-subtitle'}>
                    More specifics to understand before understanding the How of Hormonal Health!
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Do you ever wonder why you feel what you do? Hungry? Sleepy? Grumpy? Sweaty palms?  Pull back the curtain...hormones may be responsible for those feelings.
“Hormones control a host of our bodies’ functions,” says Troy Dillard, MD, a PeaceHealth endocrinologist in Bellingham, Washington.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    <b>“They are molecules that exert an effect on almost every cell in the body,” he notes. Hormones regulate everything from heart rate, metabolism, appetite, mood, reproduction, growth and development, sleep cycles and more.</b>
                </div>
                <div className={'hormonal-health-sub-subtitle-alt'}>
                    Your endocrine system—it's complicated!!
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Your hormones are generated by your endocrine system, which is made up of glands and other parts of your body that make and release various hormones.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    There are over 35 unique hormones and science knows much about these hormones and their actions, but we are still discovering so many things related to hormones.  For example, we are still avidly researching the way our environment impacts our hormones, from substances in plastics (like BPA), to flame retardants that are everywhere, in furniture, curtains, and carpets, says Dr. Dillard. 
                </div>
                <div className={'hormonal-health-paragraph'}>
                    There can be many hormonal “disruptors” in our environment. Some hormones play more than one role, like players on a baseball team with assigned roles that back each other up in various situations. 
                </div> 
                <div className={'hormonal-health-paragraph'}>
                    Hormones, their interactions with cells, each other and our environment are complex and endocrinologists are hard at work researching and trying understand this complexity and how it impacts our health, he says. You might also compare hormones to instruments in an orchestra, playing different parts of a complicated piece, ideally in sync.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    So, if hormones control so much, is there anything you can do to control your hormones?
                </div>  
                <div className={'hormonal-health-paragraph'}>
                    Yes, no and it depends, says Dr. Dillard.
                </div> 
                <div className={'hormonal-health-sub-subtitle-alt'}>
                    What's within our control?
                </div> 
                <div className={'hormonal-health-paragraph'}>
                    Some hormones aren’t at all within our control. Sometimes there’s nothing we can do—or not do—to alter some of them.  Sometimes that’s good—since many have to do with growth, development and large life changes.  At other times, that’s bad. For example, in type 1 diabetes, the pancreas can no longer make insulin and patients with diabetes have to inject it daily.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    However, there are a lot of things we do every day that trigger our hormones.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    “Technically, we can’t ‘control’ hormones, but we can certainly do things to influence them,” Dr Dillard notes. “Foods we choose to eat or drink can cause our hormone levels to go up or down. Extra weight can also have a significant impact on the way hormones work.”
                </div>
                <div className={'hormonal-health-paragraph'}>
                    “Technically, we can’t ‘control’ hormones, but we can certainly do things to influence them,” Dr Dillard notes. “Foods we choose to eat or drink can cause our hormone levels to go up or down. Extra weight can also have a significant impact on the way hormones work.”
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Here are examples of how the level of some common hormones can make you feel and what you might do to change them:
                    <ul>
                        <li className={'hormonal-health-bullet'}><span style={{color: '#c00103'}}>Sweaty palms, shallow breathing, high blood pressure and anxiety</span> are good signs that you’re getting a jolt of stress-induced hormones, cortisol or adrenaline (or ephinephrine). It happens when you’re scared or in tense situations that give you a “fight or flight” feeling. To bring those levels down, try deep breathing. Laughter, exercise and meditation can also positively counteract these hormones.  Sometimes, more evaluation and blood testing is required by your physician to be sure these aren’t excessively elevated due to endocrine conditions that can lead to sustained elevations.</li>
                        <li className={'hormonal-health-bullet'}><span style={{color: '#c00103'}}>When your stomach growls,</span> that’s the ghrelin talking—the hormone that tells us we’re hungry. Eating, of course, tames the rumble, but be sure to choose foods that will satisfy for longer periods of time.</li>
                        <li className={'hormonal-health-bullet'}><span style={{color: '#c00103'}}>Some women and, less commonly, men, have problems with thyroid hormones</span> that regulate body temperature, metabolism and energy levels, among other things. If you have thyroid issues, your doctor will recommend treatment and may encourage you to be cautious about certain foods such as kelp, seaweed, kale, broccoli and spinach, depending on your condition.</li>
                    </ul>
                </div>
                <div className={'hormonal-health-sub-subtitle-alt'}>
                    What's normal and what's not?
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Like any other part of the body, things can go wrong with hormones. If your hormones are out of balance, you might notice different symptoms, depending on which hormone or hormones are affected, says Bhavini Bhavsar, MD, a PeaceHealth endocrinologist in Vancouver, Washington. Discuss this with your doctor so that the right testing can be done.
“It’s also important to remember that it will be normal for hormone levels to fluctuate at different times in your life,” she notes.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    While you can try to affect specific hormones with certain foods or activities, remember that hormones typically rely on and play off of each other. Rarely can you affect just one hormone without having an impact on others since they work in concert with each other. If you want to effect a positive change in your hormones, it’s best to choose actions that help them all.
                </div>
                <div className={'hormonal-health-subtitle'}>
                    Now, let’s have a deep dive into 10 natural ways to balance your hormones!!
                </div> 
                <div className={'hormonal-health-sub-subtitle-alt'}>
                    The How to part of having a healthy hormonal health
                </div>
                <div className={'hormonal-health-paragraph'}>
                    We now know that, hormones are chemical messengers that play a major role in controlling your appetite, weight and mood, among other things. 
                </div>
                <div className={'hormonal-health-paragraph'}>
                     Normally, your endocrine glands produce the precise amount of each hormone needed for various processes in your body.
                </div> 
                <div className={'hormonal-health-paragraph'}>
                    However, hormonal imbalances have become increasingly common with today’s fast-paced modern lifestyle. In addition, certain hormones decline with age, and some people experience a more dramatic decrease than others.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Fortunately, a nutritious diet and other healthy lifestyle behaviors may help improve your hormonal health and allow you to feel and perform your best.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Here are the do’s for optimal hormonal health:
                    <ol>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Eat Enough Protein at Every Meal</b></p> <p>Consuming an <a href="https://www.healthline.com/nutrition/how-much-protein-per-day">adequate amount of protein</a> is extremely important.</p><p>Dietary protein provides essential amino acids that your body can’t make on its own and must be consumed every day in order to maintain muscle, bone and skin health.</p><p>In addition, protein influences the release of <a href="https://www.healthline.com/nutrition/9-fixes-for-weight-hormones">hormones that control appetite</a> and food intake.</p><p>&#10004; Consuming adequate protein triggers the production of hormones that suppress appetite and help you feel full. Aim for a minimum of 20–30 grams of protein per meal.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Engage in Regular Exercise</b></p> <p>Physical activity can strongly influence hormonal health. A major <a href="https://www.healthline.com/nutrition/10-benefits-of-exercise">benefit of exercise</a> is its ability to reduce insulin levels and increase insulin sensitivity.</p><p>Insulin is a hormone that has several functions. One is allowing cells to take up sugar and amino acids from the bloodstream, which are then used for energy and maintaining muscle. High insulin levels have been linked to inflammation, heart disease, diabetes and cancer.</p><p>&#10004; Performing strength training, aerobics, walking or other forms of physical activity can modify hormone levels in a way that reduces the risk of disease and protects muscle mass during the aging process.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Avoid Sugar and Refined Carbs</b></p><p><a href="https://www.healthline.com/nutrition/how-much-sugar-per-day">Sugar</a> and refined carbs have been linked to a number of health problems.</p><p>Indeed, avoiding or minimizing these foods may be instrumental in optimizing hormone function and avoiding obesity, diabetes and other diseases. Importantly, fructose makes up at least half of most types of sugar. Studies have consistently shown that fructose can increase insulin levels and promote insulin resistance, especially in overweight and obese people with prediabetes or diabetes. Avoiding <a href="https://www.healthline.com/nutrition/13-ways-sugary-soda-is-bad-for-you">sugar-sweetened beverages</a> may be one of the best things you can do to improve your hormone balance.</p> <p>&#10004; Diets high in sugar and refined carbs have been shown to drive insulin resistance. Avoiding these foods and reducing overall carb intake may decrease insulin levels and increase insulin sensitivity.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Learn to Manage Stress</b></p><p>Stress can wreak havoc on your hormones. Two major hormones affected by stress are cortisol and adrenaline, which is also called epinephrine.</p><p><a href="https://www.healthline.com/nutrition/ways-to-lower-cortisol">Cortisol</a> is known as “the stress hormone” because it helps your body cope with stress over the long term.</p><p>Adrenaline is the “fight-or-flight” hormone that provides your body with a surge of energy to respond to immediate danger.</p><p>Chronic stress causes cortisol levels to remain elevated, which can lead to excessive calorie intake and obesity, including increased belly fat. Elevated adrenaline levels can cause high blood pressure, rapid heart rate and anxiety.</p><p>&#10004; Try to devote at least 10–15 minutes per day to stress-reducing activities, even if you don’t feel you have the time. Engaging in stress-reduction behaviors like meditation, yoga, massage and listening to soothing music can help normalize your levels of the stress hormone cortisol.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Consume Healthy Fats</b></p><p>Including high-quality natural fats in your diet may help reduce insulin resistance and appetite.</p><p><a href="https://www.healthline.com/nutrition/mct-oil-101">Medium-chain triglycerides</a>  (MCTs) are unique fats that are taken up directly by the liver for immediate use as energy.</p><p>They have been shown to reduce insulin resistance in overweight and obese people, as well as in people with diabetes (<a href="https://pubmed.ncbi.nlm.nih.gov/17570262/">38Trusted Source</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/1568535/">39Trusted Source</a>).</p><p>MCTs are found in coconut oil, palm oil and pure MCT oil.</p><p>&#10004; To optimize hormone health, consume a healthy fat source at each meal. Including healthy natural fats in your diet and avoiding unhealthy trans fats can help reduce insulin resistance and stimulate the production of hormones that help control appetite.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Avoid overeating and undereating</b></p><p>Eating too much or too little may result in hormonal shifts that lead to weight problems.</p><p>Overeating is shown to increase insulin levels and reduce insulin sensitivity, especially in overweight and obese people who are insulin resistant. On the other hand, cutting your calorie intake too much can increase levels of the stress hormone cortisol, which is known to promote weight gain when it’s elevated.</p><p>&#10004; Eating within your own personal <a href="https://www.healthline.com/nutrition/how-many-calories-per-day">calorie range</a> can help you maintain hormonal balance and a healthy weight. Consuming too many or too few calories can lead to hormonal imbalances. Aim to eat at least 1,200 calories per day for optimal health.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Drink Green Tea</b></p><p><a href="https://www.healthline.com/nutrition/top-10-evidence-based-health-benefits-of-green-tea">Green tea</a> is one of the healthiest beverages around.</p><p>In addition to metabolism-boosting caffeine, it contains an antioxidant known as epigallocatechin gallate (EGCG), which has been credited with several health benefits.</p><p>&#10004; You may want to consider drinking one to three cups green tea per day. Green tea has been linked to increased insulin sensitivity and lower insulin levels for people who are overweight, obese or have diabetes.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Eat Fatty Fish Often</b></p><p>Fatty fish is by far the best source of long-chain <a href="https://www.healthline.com/nutrition/17-health-benefits-of-omega-3">omega-3 fatty acids</a>, which have impressive anti-inflammatory properties.</p><p>Research suggests they may also have beneficial effects on hormonal health, including reducing levels of the stress hormones cortisol and adrenaline.</p><p>&#10004; For optimal health, include two or more servings per week of fatty fish like salmon, sardines, herring and mackerel.  Long-chain omega-3 fatty acids may help lower cortisol and epinephrine, increase insulin sensitivity and decrease insulin levels in obese and insulin-resistant individuals.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Get Consistent, High-Quality Sleep</b></p><p>No matter how nutritious your diet is and how much exercise you get, your health will suffer if you don’t get enough restorative sleep.</p><p>Poor sleep has been linked to imbalances of many hormones, including insulin, cortisol, leptin, ghrelin and growth hormone</p><p>&#10004; To maintain optimal hormonal balance, aim for at least seven hours of high-quality <a href="https://www.healthline.com/nutrition/17-tips-to-sleep-better">sleep per night</a>. Inadequate or poor-quality sleep has been shown to decrease fullness hormones, increase hunger and stress hormones, reduce growth hormone and increase insulin resistance.</p>
                        </li>
                        <li className={'hormonal-health-bullet'}>
                            <p style={{color:'#000000'}}><b>Consume a High-Fiber Diet</b></p><p>Fiber, especially the soluble type, is an important component of a healthy diet.</p><p>Studies have found that it increases insulin sensitivity and stimulates the production of hormones that make you feel full and satisfied</p><p>&#10004; To protect against insulin resistance and overeating, make sure you eat <a href="https://www.healthline.com/nutrition/22-high-fiber-foods">fiber-rich foods</a> on a daily basis. High fiber intake has been linked to improvements in insulin sensitivity and the hormones that control hunger, fullness and food intake.</p>
                        </li>
                    </ol>
                </div>
                <div className={'hormonal-health-subtitle'}>
                    Conclusion
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Your hormones are involved in every aspect of your health. You need them in very specific amounts for your body to function optimally.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Hormonal imbalances may increase your risk of obesity, diabetes, heart disease and other health problems.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Despite the fact that aging and other factors are beyond your control, there are many steps you can take to help your hormones function optimally.
                </div>
                <div className={'hormonal-health-paragraph'}>
                    Consuming nutritious foods, exercising on a regular basis and engaging in other healthy behaviors can go a long way toward improving your hormonal health.
                </div>
                <div className={'hormonal-health-sub-subtitle-alt2'}>
                    Enjoy the Journey!! More to come on the next topic …
                </div> 
                <div className={'hormonal-health-sub-subtitle-alt2'}>
                    Please Share your feedback at email: contact.naturomind@gmail.com
                </div>
            </div>
        );
    }
}