import React from 'react';
import './HowItHelps.css';
import how_it_helps_2 from './how-it-helps-2.png';

export default class HowItHelps extends React.Component{
    render() {
        return (
            <div className={'how-it-helps-wrapper-div'}>
                <div className={'how-it-helps-title'}>
                    Prevention is Better than Cure - A Holistic Approach to Health
                </div>
                <div className={'container'}>
                    <img src={how_it_helps_2} className="card-img-top" alt="..."/>
                </div>
            </div>
        );
    }
}