import React from 'react';
import './ImmunityInflammation.css';
import diet from './diet.jpg';
import immune_system from './immune_system.jpg';

export default class ImmunityInflammation extends React.Component{
    render() {
        return (
            <div className={'immunity-inflammation-wrapper-div'}>
                <div className={'immunity-inflammation-title'}>
                    {`Immunity & Inflammation`}
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Focus:</b> Immunity, Inflammation & Health of Lymphatic System:  (Mainly: What is it? (Knowledge, informational), Why? (Prevention is better, protect it/ sustain it), How? (More solution oriented through good lifestyle/ sleep & quality, Diet/ Nutrition, exercise/ healthy weight range & Stress management)
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`We all know how a healthy Immune system is required to ward off daily germs, bacteria etc. In the wake of the latest COVID pandemic, this has become more important.`}
                </div>
                <div className={'immunity-inflammation-subtitle'}>
                    What is the Lymphatic system?
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`For this, one should understand the lymphatic system. The lymphatic system is part of the immune system. It also maintains fluid balance and plays a role in absorbing fats and fat-soluble nutrients.`}
                </div>
                <div className={'immunity-inflammation-subtitle'}>
                    Lymphatic System Functions
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`The lymphatic system has three main functions:`}
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}>{`It maintains the balance of fluid between the blood and tissues, known as fluid homeostasis.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`It forms part of the body’s immune system and helps defend against bacteria and other intruders.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`It facilitates absorption of fats and fat-soluble nutrients in the digestive system.`}</li>
                </ul>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Fluid Balance</b>
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}>{`The lymphatic system helps maintain fluid balance. It returns excess fluid and proteins from the tissues that cannot be returned through the blood vessels.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`The fluid is found in tissue spaces and cavities, in the tiny spaces surrounding cells, known as the interstitial spaces. These are reached by the smallest blood and lymph capillaries.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`Around 90 percent of the plasma that reaches tissues from the arterial blood capillaries is returned by the venous capillaries and back along veins. The remaining 10 percent is drained back by the lymphatics.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`Each day, around 2-3 liters is returned. This fluid includes proteins that are too large to be transported via the blood vessels.`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`Loss of the lymphatic system would be fatal within a day. Without the lymphatic system draining excess fluid, our tissues would swell, blood volume would be lost and pressure would increase.`}</li>
                </ul>
                <div className={'immunity-inflammation-paragraph'}>
                    {`Are you with me?  So far, we understand how a healthy & optimal lymphatic system can help build a strong immune system.`}
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`There is one more critical aspect which cannot be ignored. You all must have heard about Inflammation. Inflammation is said to be the cause of every disease small & big. Everyone’s goal is to keep inflammation in the body to a minimal level before it gets chronic.  How do we do this? The answer is by following a positive healthy lifestyle.`}
                </div>
                <div className={'immunity-inflammation-subtitle'}>
                    Positive Healthy Lifestyle
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`This is comprised of four key pillars like: diet/ nutrition, exercise, stress management & sleep. Let’s drill down to each of these to understand the gist. It may seem a lot but with a right strategy that fits each one’s routine and aspects of life, there is an optimal balance all can achieve.`}
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Diet / Nutrition</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`It is said that the body & mind is what you feed it. Modern lifestyle often contributes to immune dysfunction. Thus, optimal nutrition for the best immunological outcomes would be nutrition, which supports the functions of immune cells allowing them to initiate effective responses against pathogens but also to resolve the response rapidly when necessary and to avoid any underlying chronic inflammation. The focus should be on Total nutrient Intake. Intake of adequate calories and micronutrients is vital for optimal immune function. Deficiency in total calories and/or protein, as seen in parts of the world stricken with starvation, severely reduces the immune system’s ability to respond.`}
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Start with these food anti-inflammatory foods:</b> Most Important avoid all processed foods & drink enough water.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Superfoods</b> carry an abundance of the following essential building blocks for a strong immune system:
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}><b>Antioxidants:</b> Help neutralize free radicals in the body that are closely linked to numerous diseases and conditions</li>
                    <li className={'immunity-inflammation-bullet'}><b>Essential Fatty Acids:</b> Healthy, “good” fats that are essential for maintaining optimal health. They’re divided into two groups, Omega-3s and Omega-6s, and are considered essential because the body cannot not produce them on its own</li>
                    <li className={'immunity-inflammation-bullet'}><b>Vitamin A:</b> Critical for normal vision, reproduction, and the immune system</li>
                    <li className={'immunity-inflammation-bullet'}><b>Vitamin C:</b> Helps white blood cells function and is crucial to growth, development, and repairing tissues</li>
                    <li className={'immunity-inflammation-bullet'}><b>Vitamin K:</b> Supports strong bones, prevents blood clots and excessive bleeding</li>
                    <li className={'immunity-inflammation-bullet'}><b>B Vitamins:</b> The eight B vitamins are a vital part of good health, responsible for red blood cell growth, healthy appetite, high energy levels, proper brain and nerve function, and muscle tone</li>
                    <li className={'immunity-inflammation-bullet'}><b>Carotenoids:</b> A plant-based nutrient that creates bright red, yellow, and orange pigments in fruits and veggies, which are potent antioxidants.</li>
                    <li className={'immunity-inflammation-bullet'}><b>Iron:</b> Essential component of hemoglobin, the part of red blood cells that carries oxygen throughout the body</li>
                    <li className={'immunity-inflammation-bullet'}><b>Magnesium:</b> Relays signals between the brain and body, supports nerve function, maintains a steady heartbeat, and regulates muscle contractions</li>
                    <li className={'immunity-inflammation-bullet'}><b>Potassium:</b> A crucial electrolyte that has benefits similar to magnesium, such as maintaining a steady heartbeat, nervous system health, and regulating fluid levels in the body</li>
                    <li className={'immunity-inflammation-bullet'}><b>Calcium:</b> Nearly all of the calcium in the body is in the bones and teeth. Calcium is absolutely imperative for skeletal health, bone growth, and combating osteoporosis</li>
                </ul>
                <div className={'container'}>
                    <img src={diet}  alt="..."/>
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Immune-Boosting Foods for Every Age Group</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`Summer isn’t typically a time of year that people associate with getting sick. But the reality is that you can come down with a nasty cold or the flu anytime your immune system becomes compromised. This can happen when you’re stressed out, have a hectic schedule, or traveling often.`}
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    {`While you don’t always have control over these factors, there is one important thing you can do: feed your body nutritious, immunity-boosting foods. Read on to learn how you can keep your immune system going strong, no matter what life throws at you!`}
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Why Some Foods Boost Your Immunity</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    For your immune system to run at peak performance, your body needs a significant amount of essential vitamins and minerals. The best way to achieve this is by eating a diet rich in whole, unprocessed foods. But there is a category of “superfoods” — whole foods that offer maximum nutritional benefits with minimal calories — that are particularly great for boosting immunity.
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>The Best Immunity-Boosting Foods for All Ages</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    The list of immunity-boosting foods is long, and nearly everyone can benefit from any superfood, regardless of age. However, each age group has its own specific immune-boosting needs for growth and development, maintenance, or staying healthy while aging.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Babies & Children</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Try these palatable, easy-to-digest superfoods that help kids’ mental and physical development, while keeping their immune systems churning away:
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}><b>Whole-fat plain yogurt:</b> Loaded with probiotics, the good bacteria that helps your child fend off harmful bacteria in the digestive system, and also vitamin D for strong bones</li>
                    <li className={'immunity-inflammation-bullet'}><b>Sweet potatoes:</b> Packed with carotene and Vitamin A for developing keen eyesight</li>
                    <li className={'immunity-inflammation-bullet'}><b>Avocado:</b> Has vitamins E, B6, and Omega-3s, which builds resistance to viruses</li>
                    <li className={'immunity-inflammation-bullet'}><b>Brown rice:</b> High in magnesium, promoting healthy nervous system function</li>
                    <li className={'immunity-inflammation-bullet'}><b>Blueberries:</b> Superior source of antioxidants and Vitamin C, which can prevent and shorten colds</li>
                </ul>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Teens</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Calcium and iron are two essential nutrients for teens. They help develop strong bones and keep their energy levels high. Here are some great immunity-boosting meals and snacks that are higher in calories to suit teens’ fast metabolisms, but are still rich in healthy nutrients:
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}><b>Granola and skim milk:</b> Taking it up a notch from plain old cereal, granola has more iron and the skim milk provides calcium without saturated fat</li>
                    <li className={'immunity-inflammation-bullet'}><b>Frozen yogurt with walnuts:</b> Many teens have a sweet tooth, so swap out the cookies and ice cream for frozen yogurt (high in calcium) with walnuts, for iron and Omega-3s</li>
                    <li className={'immunity-inflammation-bullet'}><b>Hummus and veggies:</b> Hummus contains lots of calcium and magnesium. Bonus: Dip red, orange, or yellow bell peppers into the creamy snack for a major carotenoid boost</li>
                    <li className={'immunity-inflammation-bullet'}><b>Nut Butters:</b> Nut butters are very satiating and have the Omega-3s and iron teens need for great health</li>
                    <li className={'immunity-inflammation-bullet'}><b>Whole wheat pasta and fresh tomato sauce:</b> Whole wheat pasta provides twice the iron of regular pasta and gives teens the energy they need. Fresh tomato sauce is low in calories, full of vitamins C and E, and brimming with calcium to ward off illness</li>
                </ul>
                <div className={'container'}>
                    <img src={immune_system}  alt="..."/>
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Exercise</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Physical activity may help flush bacteria out of the lungs and airways. This may reduce your chance of getting a cold, flu, or other illness. <b>Exercise</b> causes change in antibodies and white blood cells (WBC). WBCs are the body's <b>immune system</b> cells that fight disease. Researchers found that regular walking may lead to a higher number of white blood cells, which fight infections. Research has found a link between moderate exercise and a strong immune system. Remember too much intense exercise can reduce immunity and may even make you sick.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Recommended regular exercise</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Choose exercise activities that strengthen muscle and bones.  Experts recommend that teens do 60 minutes of physical activity every day. Most of that should be moderate to vigorous aerobic activity. Aerobic activity is anything that gets your heart going — like biking, dancing, or running. Then take a few minutes for warm up & some strength training. Exercises like these help build muscle and boost metabolism. Flexibility is the third component of well-rounded exercise. Check out Yoga is one way to stay flexible.
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Stress Management</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    When we're stressed, the immune system's ability to fight off antigens is reduced & more susceptible to infections. The stress hormone corticosteroid can suppress the effectiveness of the immune system (e.g. lowers the number of lymphocytes).
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Chronic stress causes immunosuppression</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Stress is known to suppress immune function and increase susceptibility to infections and cancer. Chronic or long-term stress can suppress immunity by decreasing immune cell numbers and function and/or increasing active immunosuppressive mechanisms (e.g. regulatory T cells).
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    <b>Ways to combat stress</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Consciously avoid falling in the trap of negative self-talk leading to negative thinking. Learning to frame things positively will help develop one’s resilience to stress, Find a healthy balance between favorite activities and free time, Research has found that expressing oneself in writing can help reduce mental distress and improve well-being.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Manage stress and establish positive mental health habits: by spending time in nature, talking about stressful situations with a trusted adult can help put things in perspective and find solutions.
                </div>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    <b>Sleep</b>
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Last but not least, the importance of sleep cannot be underestimated. During sleep, your immune system releases proteins called cytokines, some of which help promote sleep. Certain cytokines need to increase when you have an infection or inflammation, or when you're under stress. Sleep deprivation may decrease production of these protective cytokines.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Getting adequate sleep helps with stress management. A good night's sleep makes you able to tackle the day's stress more easily. When you are tired, you are less patient and more easily agitated, which can increase stress
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Recommended hours of sleep for teenagers: 8-9 hours.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Sleep supports the proteins and cells of your immune system to detect and destroy bugs and germs – it also helps them to remember them, so they can fight them off even quicker in future. Sleeps strengthen your body's immune response, and at times like these it's more important than ever that we're getting enough of it
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Where do we start: Don’t rush, Start Simple, one change at a time to form a habit.
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    Here are tips in each of the categories which you can choose and pick to start on the journey of building and maintaining a strong immune system which can ward off various infections naturally.
                </div>
                <div className={'immunity-inflammation-subtitle'}>
                    Conclusion
                </div>
                <div className={'immunity-inflammation-paragraph'}>
                    In this Special Issue of Nutrients, the collected works provide a breadth of reviews and research indicating the key influence of nutrients and nutrition on immune responses in health and disease and across the life course. Nutrients may impact directly or indirectly upon immune cells causing changes in their function or may exert effects via changes in the gut microbiome. A better understanding of the role of nutrients in immune function will facilitate the use of bespoke nutrition to improve human health.
                </div>
                <br/>
                <div className={'immunity-inflammation-sub-subtitle'}>
                    Enjoy the Journey!! More to come on the next topic …
                </div> 
                <div className={'immunity-inflammation-sub-subtitle'}>
                    Please Share your feedback at email: contact.naturomind@gmail.com
                </div>
            </div>
        );
    }
}