import React from 'react';
import './PrivacyPolicy.css';

export default class PrivacyPolicy extends React.Component{
    render() {
        return (
            <div className={'privacy-policy-wrapper-div'}>
                <div className={'privacy-policy-title'}>
                    {`Privacy Policy`}
                </div>
                <div className={'privacy-policy-subtitle'}>
                    Who Are We and What This Policy Covers
                </div>
                <div className={'privacy-policy-paragraph'}>
                    {`This Privacy Policy applies to the following:`}
                </div>
                <ul>
                    <li className={'immunity-inflammation-bullet'}>{`This website (nautormind.com)`}</li>
                    <li className={'immunity-inflammation-bullet'}>{`The Naturomind iOS app`}</li>
                </ul>
                <div className={'privacy-policy-subtitle'}>
                    Information We Collect
                </div>
                <div className={'privacy-policy-paragraph'}>
                    {`Neither this website nor the Naturomind iOS app collect any user-related data. Neither of them have any concept of user accounts, and neither have any type of activity logs.`}
                </div>
            </div>
        );
    }
}