import React from 'react';
import './DiabetesHeartHealth.css';

export default class DiabetesHeartHealth extends React.Component{
    render() {
        return (
            <div className={'diabetes-heart-health-wrapper-div'}>
                <div className={'diabetes-heart-health-title'}>
                    Heard about Diabetes?
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Many people have it. There has been a steady increase in the worldwide diabetic numbers.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes can strike anyone, from any walk of life. And it does in numbers that are dramatically increasing. Today, more than 30 million Americans have been diagnosed with diabetes. Worldwide, more than 422 million people have diabetes.  
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Have a look at the numbers & key facts published below.
                    <p><b>WHO published –</b></p>
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>The number of people with diabetes rose from 108 million in 1980 to 422 million in 2014.</li>
                        <li className={'diabetes-heart-health-bullet'}>The global prevalence of diabetes* among adults over 18 years of age rose from 4.7% in 1980 to 8.5% in 2014 (1).</li>
                        <li className={'diabetes-heart-health-bullet'}>Between 2000 and 2016, there was a 5% increase in premature mortality from diabetes.</li>
                        <li className={'diabetes-heart-health-bullet'}>Diabetes prevalence has been rising more rapidly in low- and middle-income countries than in high-income countries.</li>
                        <li className={'diabetes-heart-health-bullet'}>Diabetes is a major cause of blindness, kidney failure, heart attacks, stroke and lower limb amputation.</li>
                        <li className={'diabetes-heart-health-bullet'}>In 2016, an estimated 1.6 million deaths were directly caused by diabetes. Another 2.2 million deaths were attributable to high blood glucose in 2012.</li>
                        <li className={'diabetes-heart-health-bullet'}>Almost half of all deaths attributable to high blood glucose occur before the age of 70 years. WHO estimates that diabetes was the seventh leading cause of death in 2016.</li>
                        <li className={'diabetes-heart-health-bullet'}>A healthy diet, regular physical activity, maintaining a normal body weight and avoiding tobacco use are ways to prevent or delay the onset of type 2 diabetes.</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes can be treated and its consequences avoided or delayed with diet, physical activity, medication and regular screening and treatment for complications.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Here are more stats. The IDF Diabetes Atlas Ninth edition 2019 provides the latest figures, information and projections on diabetes worldwide.
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Approximately 463 million adults (20-79 years) were living with diabetes; by 2045 this will rise to 700 million</li>
                        <li className={'diabetes-heart-health-bullet'}>The proportion of people with type 2 diabetes is increasing in most countries</li>
                        <li className={'diabetes-heart-health-bullet'}>79% of adults with diabetes were living in low- and middle-income countries</li>
                        <li className={'diabetes-heart-health-bullet'}>1 in 5 of the people who are above 65 years old have diabetes</li>
                        <li className={'diabetes-heart-health-bullet'}>1 in 2 (232 million) people with diabetes were undiagnosed</li>
                        <li className={'diabetes-heart-health-bullet'}>Diabetes caused 4.2 million deaths</li>
                        <li className={'diabetes-heart-health-bullet'}>Diabetes caused at least USD 760 billion dollars in health expenditure in 2019 – 10% of total spending on adults</li>
                        <li className={'diabetes-heart-health-bullet'}>More than 1.1 million children and adolescents are living with type 1 diabetes</li>
                        <li className={'diabetes-heart-health-bullet'}>More than 20 million live births (1 in 6 live births) are affected by diabetes during pregnancy</li>
                        <li className={'diabetes-heart-health-bullet'}>374 million people are at increased risk of developing type 2 diabetes</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Based on these numbers, it is quiet clear that prevention for Diabetics should be on top of everyone’s mind & cannot be ignored.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    There is some good news as well! 
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    <b>Diabetes 'Cure':</b> Diet & <b>Exercise</b> Work for Some. People with Type 2 <b>diabetes can</b> reverse their condition with diet and <b>exercise</b>, although remission is not very common, according to a new study from the Centers for Disease Control and Prevention.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    It’s rightly said, devil is in the details. Let’s have a deep dive into the next steps.
                </div>
                <div className={'diabetes-heart-health-subtitle'}>
                    What is Diabetes?
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes is a serious condition that causes higher than normal blood sugar levels. Diabetes occurs when your body cannot make or effectively use its own insulin, a hormone made by special cells in the pancreas called islets (eye-lets). Insulin serves as a “key” to open your cells, to allow the sugar (glucose) from the food you eat to enter. Then, your body uses that glucose for energy.
                    <p>Main types of diabetics:</p>
                    <ol>
                        <li className={'diabetes-heart-health-bullet'}><p><b>Type 1 diabetes:</b> Type 1 diabetes, previously known as juvenile diabetes, is the most severe form of the disease. Type 1 diabetes has also been called juvenile diabetes because it usually develops in children and teenagers. But people of all ages can develop type 1 diabetes.</p>
<p>In type 1 diabetes, the body’s immune system attacks the insulin-producing islet cells in the pancreas. The islet cells sense glucose in the blood and produce the right amount of insulin to normalize blood sugars. This attack on the body's own cells is known as autoimmune disease. Scientists are not sure why the autoimmune attack happens.</p>
<p>But once the insulin-producing cells are destroyed, a person can no longer produce their own insulin. Without insulin, there is no “key.” So, the sugar stays in the blood and builds up. As a result, the body’s cells starve. And, if left untreated, high blood sugar levels can damage eyes, kidneys, nerves, and the heart, and can also lead to coma and death.</p>
So type 1 diabetes must be treated through a daily regimen of insulin therapy.
                            <div className={'diabetes-heart-health-sub-subtitle-inner'}>
                                What are the Warning Signs of Type 1 Diabetes?
                            </div>
                            <div className={'diabetes-heart-health-paragraph-inner'}>
                                The onset of type 1 diabetes happens very quickly. The following symptoms may appear suddenly and are too severe to overlook:
                                <ul>
                                    <li className={'diabetes-heart-health-bullet'}>Increased thirst</li>
                                    <li className={'diabetes-heart-health-bullet'}>Increased urination (bed-wetting may occur in children who have already been toilet trained)</li>
                                    <li className={'diabetes-heart-health-bullet'}>Rapid and unexplained weight loss</li>
                                    <li className={'diabetes-heart-health-bullet'}>Extreme hunger</li>
                                    <li className={'diabetes-heart-health-bullet'}>Extreme weakness or fatigue</li>
                                    <li className={'diabetes-heart-health-bullet'}>Unusual irritability</li>
                                    <li className={'diabetes-heart-health-bullet'}>Blurred vision</li>
                                    <li className={'diabetes-heart-health-bullet'}>Nausea, vomiting and abdominal pain</li>
                                    <li className={'diabetes-heart-health-bullet'}>Unpleasant breath odor</li>
                                    <li className={'diabetes-heart-health-bullet'}>Itchy skin</li>
                                </ul>
                            </div>
                       </li>
                       <li className={'diabetes-heart-health-bullet'}><p><b>Type 2 diabetes:</b> The most common form of diabetes is called type 2 diabetes, or non-insulin dependent diabetes. About 90% of people with diabetes have type 2. Type 2 diabetes is also called adult onset diabetes, since it typically develops after age 35. However, a growing number of younger people are now developing type 2 diabetes.</p>
                       <p>People with type 2 diabetes are able to produce some of their own insulin. Often, it’s not enough. And sometimes, the insulin will try to serve as the “key” to open the body’s cells, to allow the glucose to enter. But the key won’t work. The cells won’t open. This is called insulin resistance. Type 2 diabetes is typically tied to people who are overweight with a sedentary lifestyle.</p>
                            <div className={'diabetes-heart-health-sub-subtitle-inner'}>
                                Warning Signs of Type 2 Diabetes?
                            </div>
                            <div className={'diabetes-heart-health-paragraph-inner'}>
                                The symptoms of type 2 diabetes are similar to those of type 1 diabetes. But the onset of type 2 diabetes is usually slower and the symptoms are not as noticeable as those for type 1 diabetes. For these reasons, many people mistakenly overlook the warning signs. They also might think that the symptoms are the signs of other conditions, like aging, overworking or hot weather.
                            </div>
                       </li>
                       <li className={'diabetes-heart-health-bullet'}><p><b>Gestational diabetes:</b> which occurs during pregnancy. Gestational diabetes is diabetes diagnosed for the first time during pregnancy (gestation). Like other types of diabetes, gestational diabetes affects how your cells use sugar (glucose). Gestational diabetes causes high blood sugar that can affect your pregnancy and your baby's health.</p>
                       <p>Expectant mothers can help control gestational diabetes by eating healthy foods, exercising and, if necessary, taking medication. Controlling blood sugar can keep you and your baby healthy & delivery complications.</p> <p>In women with gestational diabetes, blood sugar usually returns to normal soon after delivery. But if you've had gestational diabetes, you have a higher risk of getting type 2 diabetes. You'll need to be tested for changes in blood sugar more often.</p>
                       <p>In all these types of diabetics, hyper glycaemia, or raised blood sugar, is a common effect of uncontrolled diabetes and over time leads to serious damage to many of the body's systems, especially the nerves and blood vessels.</p>
                            <div className={'diabetes-heart-health-sub-subtitle-inner'}>
                                Warning Signs of Type 2 Diabetes?
                            </div>
                            <div className={'diabetes-heart-health-paragraph-inner'}>
                                The symptoms of type 2 diabetes are similar to those of type 1 diabetes. But the onset of type 2 diabetes is usually slower and the symptoms are not as noticeable as those for type 1 diabetes. For these reasons, many people mistakenly overlook the warning signs. They also might think that the symptoms are the signs of other conditions, like aging, overworking or hot weather.
                            </div>
                       </li>
                    </ol>
                </div>
                <div className={'diabetes-heart-health-sub-subtitle-alt'}>
                    Why is it so important to prevent diabetics or maintain it in order to prevent further damage to health & life? 
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Based on all the information we went through this far, I think there is no dilemma for why prevention is necessary. Let’s review the Whys of prevention really quickly.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes can affect many major organs in your body, which can lead to an array of serious complications when left untreated. These medical problems include:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Cardiovascular disease (CVD), or heart disease, including peripheral artery disease (PAD) and stroke</li>
                        <li className={'diabetes-heart-health-bullet'}>Renal (kidney) disease</li>
                        <li className={'diabetes-heart-health-bullet'}>Unhealthy cholesterol levels that can lead to atherosclerosis</li>
                        <li className={'diabetes-heart-health-bullet'}>Metabolic syndrome</li>
                        <li className={'diabetes-heart-health-bullet'}>Vision damage</li>
                        <li className={'diabetes-heart-health-bullet'}>Nerve damage and numbness in the extremities that can lead to amputation</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    The good news is that diabetes is <span style={{color:'#3852d7'}}>treatable</span> and often preventable. If you have diabetes, you may be able to avoid or delay other health complications by:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}><span style={{color:'#3852d7'}}>Working with your health care team</span> to manage the disease, which may include the use of <span style={{color:'#3852d7'}}>medications</span></li>
                        <li className={'diabetes-heart-health-bullet'}><span style={{color:'#3852d7'}}>Knowing your numbers</span>, including your blood sugar level, weight, blood cholesterol level and blood pressure</li>
                        <li className={'diabetes-heart-health-bullet'}>Adopting a <span style={{color:'#3852d7'}}>healthy lifestyle</span></li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-sub-subtitle-alt2'}>
                    That brings us to Prevention & Treatment of diabetics: <span style={{color:'#02af50'}}>The How part??</span>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes can be successfully managed. Work with your health care team to set personal goals. And be sure to monitor your critical health numbers, including your:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Blood sugar level</li>
                        <li className={'diabetes-heart-health-bullet'}>Weight</li>
                        <li className={'diabetes-heart-health-bullet'}>Blood cholesterol level</li>
                        <li className={'diabetes-heart-health-bullet'}>Blood pressure</li>  
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    <span style={{color:'#3852d7'}}>Lifestyle changes</span> such as losing weight, eating healthy and engaging in regular, moderate physical activity may reduce the progression of Type 2 diabetes and control Type 1 diabetes. They can also minimize other risk factors such as <span style={{color:'#3852d7'}}>high blood pressure, blood cholesterol</span> and even <span style={{color:'#3852d7'}}>heart attacks</span> and <span style={{color:'#3852d7'}}>strokes</span>.
                </div> 
                <div className={'diabetes-heart-health-paragraph'}>
                    In many instances, lifestyle changes must be accompanied by <span style={{color:'#3852d7'}}>medications</span> to control blood glucose levels, high blood pressure and cholesterol. This complementary regimen may also prevent heart attack and stroke.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Like any other lifestyle disease, key pillars for preventing and managing ill effects of diabetics is quitting smoking & have a healthy diet, regular exercise routine along with stress management and minimizing it.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Searching for healthy snack ideas? Try these:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Small apple with tablespoon of peanut butter</li>
                        <li className={'diabetes-heart-health-bullet'}>½ cup baby carrots with two tablespoons of low-fat cottage cheese or hummus</li>
                        <li className={'diabetes-heart-health-bullet'}>Small handful (about an ounce) of unsalted nuts</li>
                        <li className={'diabetes-heart-health-bullet'}>Two whole-grain crackers with low-fat, low-salt string cheese</li>
                        <li className={'diabetes-heart-health-bullet'}>½ whole-wheat English muffin topped with low-fat shredded cheese and fresh tomato and broiled.</li>
                        <li className={'diabetes-heart-health-bullet'}>½ cup plain low fat or fat free Greek yogurt, topped with ½ cup blueberries and a sprinkle of cinnamon</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-subtitle'}>
                    Managing stress
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Stress affects people in different ways. It can:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Impact emotional well-being.</li>
                        <li className={'diabetes-heart-health-bullet'}>Cause various aches and pains, from headaches to stomach aches.</li>
                        <li className={'diabetes-heart-health-bullet'}>Diminish energy level.</li>
                        <li className={'diabetes-heart-health-bullet'}>Interrupt sleep.</li>
                        <li className={'diabetes-heart-health-bullet'}>Trigger various unhealthy responses, including overeating, drinking too much alcohol, smoking, procrastinating and not sleeping enough.</li>
                    </ul>
                    <p>We can't get rid of stress, but we can deal with it in a healthy way.</p>
                </div>
                <div className={'diabetes-heart-health-sub-subtitle'}>
                    Learn to Manage Your Stress
                </div>
                <div className={'diabetes-heart-health-paragraph-muted'}>
                    Your mind deserves better than to be loaded down with the never-ending job of worrying! Some stress can be beneficial and may lead to actual problem-solving, but a lot of our stress is unnecessary and even harmful.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    We all have stress — at work, at home, and on the road. Sometimes we can feel especially stressed because of a bad interaction with someone, too much work, or everyday hassles like getting stuck in traffic.
                </div> 
                <div className={'diabetes-heart-health-paragraph'}>
                    Negative stress can keep you from feeling and performing your best — mentally, physically and emotionally. But no one’s life is completely stress-free. It’s important to know how to manage the stress in your life. Try these three simple techniques for dealing with it.
                </div>
                <div className={'diabetes-heart-health-sub-subtitle'}>
                    Positive Self-Talk
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Let’s be honest, we all talk to ourselves! Sometimes we talk out loud but usually we do it in our heads. Self-talk can be positive ("I can do this" or "everything will be OK") or negative ("I'll never get better" or "I'm so stupid"). Negative self-talk increases stress. Positive self-talk can help you calm down and control stress. With practice, you can learn to shift negative thoughts to positive ones. For example:
                    <p><b>Negative to Positive</b></p>
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>"I can't do this."> "I'll do the best I can. I’ve got this."</li>
                        <li className={'diabetes-heart-health-bullet'}>"Everything is going wrong." > "I can handle this if I take one step at a time."</li>
                        <li className={'diabetes-heart-health-bullet'}>"I hate it when this happens." > "I know how to deal with this; I've done it before."</li>
                        <li className={'diabetes-heart-health-bullet'}>“I feel helpless and alone.”> “I can reach out and get help if I need it.”</li>
                        <li className={'diabetes-heart-health-bullet'}>“I can’t believe I screwed up. > "I'm human, and we all make mistakes. I can fix it."</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    To really make it work, practice positive self-talk every day — in the car, at your desk, before you go to bed or whenever you notice negative thoughts. It’s a great practice to teach kids, too!
                </div>
                <div className={'diabetes-heart-health-sub-subtitle'}>
                    Top 10 Emergency Stress-Stoppers
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Emergency stress stoppers are actions to help you defuse stress in the moment. You may need different stress stoppers for different situations, and sometimes it helps to combine them. Here are some ideas:
                    <ol>
                        <li className={'diabetes-heart-health-bullet'}>Count to 10 before you speak or react.</li> 
                        <li className={'diabetes-heart-health-bullet'}>Take a few slow, deep breaths until you feel your body un-clench a bit.</li>
                        <li className={'diabetes-heart-health-bullet'}>Go for a walk, even if it’s just to the restroom and back. It can help break the tension and give you a chance to think things through.</li>
                        <li className={'diabetes-heart-health-bullet'}>Try a quick <a href="https://www.heart.org/en/healthy-living/healthy-lifestyle/mental-health-and-wellbeing/meditation-to-boost-health-and-wellbeing">meditation</a> or prayer to get some perspective.</li> 
                        <li className={'diabetes-heart-health-bullet'}>If it’s not urgent, sleep on it and respond tomorrow. This works especially well for stressful emails and social media trolls.</li>
                        <li className={'diabetes-heart-health-bullet'}>Walk away from the situation for a while, and handle it later once things have calmed down.</li>
                        <li className={'diabetes-heart-health-bullet'}>Break down big problems into smaller parts. Take one step at a time, instead of trying to tackle everything at once.</li> 
                        <li className={'diabetes-heart-health-bullet'}>Turn on some chill music or an inspirational podcast to help you deal with road rage.</li>
                        <li className={'diabetes-heart-health-bullet'}>Take a break to pet the dog, hug a loved one or do something to help someone else.</li> 
                        <li className={'diabetes-heart-health-bullet'}>Work out or do something active. <a href="https://www.heart.org/en/healthy-living/healthy-lifestyle/stress-management/working-out-to-relieve-stress">Exercise is a great antidote for stress.</a></li> 
                    </ol>
                </div>
                <div className={'diabetes-heart-health-sub-subtitle'}>
                    Stress-Busting Activities
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Doing things you enjoy is a natural way to relieve stress and find your happy place. Even when you’re down, you may find pleasure in simple things like going for a walk, catching up with a friend, or reading a good book.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    When stress makes you feel bad, do something that makes you feel good, even if only for 10 or 15 minutes. Some of these activities may work for you:
                    <ul>
                        <li className={'diabetes-heart-health-bullet'}>Make art -- draw, color, paint, or play a musical instrument.</li>
                        <li className={'diabetes-heart-health-bullet'}>Work on a scrapbook or photo album to focus on good memories.</li>
                        <li className={'diabetes-heart-health-bullet'}>Read a book, short story or magazine.</li>
                        <li className={'diabetes-heart-health-bullet'}>Meet a friend for coffee or a meal.</li>
                        <li className={'diabetes-heart-health-bullet'}>Play a favorite sport like golf, tennis, or basketball.</li>
                        <li className={'diabetes-heart-health-bullet'}>Do a hobby like sewing, knitting, or making jewelry.</li>
                        <li className={'diabetes-heart-health-bullet'}>Play with your kids or pets – outdoors if possible.</li>
                        <li className={'diabetes-heart-health-bullet'}>Listen to music or watch an inspiring performance.</li>
                        <li className={'diabetes-heart-health-bullet'}>Take a walk in nature.</li>
                        <li className={'diabetes-heart-health-bullet'}>Take a relaxing bath and feel the stress wash away.</li>
                        <li className={'diabetes-heart-health-bullet'}>Meditate or practice yoga.</li>
                        <li className={'diabetes-heart-health-bullet'}>Work in the garden or do a home improvement project.</li>
                        <li className={'diabetes-heart-health-bullet'}>Go for a run or bike ride to clear your head.</li>
                    </ul>
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    The key is to find your groove and make it a practice. You’ll be amazed at how quickly you may start to feel better once you disrupt the cycle of stress.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Last but not least, continue to have a good sleep routine & try to get 7 to 9 hours of sleep daily.
                </div>
                <div className={'diabetes-heart-health-subtitle'}>
                    Conclusion
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Diabetes complications can lead to serious illness and even death, which is why prevention is so important. Diabetes complications can be divided into two types: acute (sudden) and chronic (long-term).
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    If uncontrolled, <b>diabetes</b> can damage your heart, blood vessels, eyes, kidneys, and nerves. This is why it is so <b>important</b> to get screened for <b>diabetes</b> and take steps to <b>prevent it</b> if you are identified to be at increased risk. <b>Diabetes</b> is a disease that occurs when your blood sugar, or glucose, is <b>too</b> high.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    The good news is that the risk of most <b>diabetes-related complications</b> can be <b>reduced</b> by keeping blood pressure, blood glucose and cholesterol levels within recommended range. Also, being a healthy weight, eating healthily, <b>reducing</b> alcohol intake, and not smoking will help <b>reduce</b> your <b>risk</b>.
                </div>
                <div className={'diabetes-heart-health-paragraph'}>
                    Implementing the right lifestyle changes with respect to diet/ nutrition, exercise & stress management will go a long way in preventing this dangerous disease or helping prevent complications for those who already have the disease.
                </div>
                <div className={'diabetes-heart-health-sub-subtitle-alt3'}>
                    Enjoy the Journey!! More to come on the next topic …
                </div> 
                <div className={'diabetes-heart-health-sub-subtitle-alt3'}>
                    Please Share your feedback at email: contact.naturomind@gmail.com
                </div>
            </div>
        );
    }
};