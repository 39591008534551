import React from 'react';
import './Cancer.css';
import cancer1 from './cancer1.jpeg';
import cancer2 from './cancer2.jpg';
import cancer3 from './cancer3.jpg';
import cancer4 from './cancer4.jpg';
import cancer5 from './cancer5.jpg';
import cancer6 from './cancer6.jpg';
import cancer7 from './cancer7.jpg';
import cancer8 from './cancer8.jpg';
import cancer9 from './cancer9.jpg';
import cancer10 from './cancer10.jpg';
import cancer11 from './cancer11.jpg';
import cancer12 from './cancer12.jpg';

export default class Cancer extends React.Component{
    render() {
        return (
            <div className={'cancer-wrapper-div'}>
                <div className={'cancer-title'}>
                    Cancer
                </div>
                <div className={'cancer-paragraph'}>
                    This needs no introduction. Everyone by now will have known someone in their family or community impacted by this cruel disease.
                </div>
                <div className={'cancer-paragraph'}>
                    <p><b>Key facts:</b></p>
                    <ul>
                        <li className={'cancer-bullet'}>Cancer is the second leading cause of death globally and is responsible for an estimated 9.6 million deaths in 2018. Globally, about 1 in 6 deaths is due to cancer. Approximately 70% of deaths from cancer occur in low- and middle-income countries.</li>
                        <li className={'cancer-bullet'}>Cancer Facts & Figures provides an overview of the international cancer burden, including the estimated numbers of new cancer cases and deaths worldwide.</li>
                        <li className={'cancer-bullet'}>Here are various stats from American Cancer Society which clearly projects the toll this disease is creating for the world : <a href="https://www.cancer.org/research/cancer-facts-statistics/global.html">https://www.cancer.org/research/cancer-facts-statistics/global.html</a></li>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    <p><b>Similar stats are projected by WHO (World Health Organization) as well:</b></p>
                    <ul>
                        <li className={'cancer-bullet'}>Cancer is the second leading cause of deaths globally and is responsible for an estimated 9.6 million deaths in 2018. Globally, about 1 in 6 deaths is due to cancer.</li>
                        <li className={'cancer-bullet'}>Approximately 70% of deaths from cancer occur in low- and middle-income countries.</li>
                        <li className={'cancer-bullet'}>Around one third of deaths from cancer are due to the 5 leading behavioral and dietary risks: high body mass index, low fruit and vegetable intake, lack of physical activity, tobacco use, and alcohol use.</li>
                        <li className={'cancer-bullet'}>Tobacco use is the most important risk factor for cancer and is responsible for approximately 22% of cancer deaths (2).</li>
                        <li className={'cancer-bullet'}>Cancer causing infections, such as hepatitis and human papilloma virus (HPV), are responsible for up to 25% of cancer cases in low- and middle-income countries.</li>
                        <li className={'cancer-bullet'}>Late-stage presentation and inaccessible diagnosis and treatment are common. In 2017, only 26% of low-income countries reported having pathology services generally available in the public sector. More than 90% of high-income countries reported treatment services are available compared to less than 30% of low-income countries.</li>
                        <li className={'cancer-bullet'}>The economic impact of cancer is significant and is increasing. The total annual economic cost of cancer in 2010 was estimated at approximately US$ 1.16 trillion.</li>
                        <li className={'cancer-bullet'}>Only 1 in 5 low- and middle-income countries have the necessary data to drive cancer policy.</li>
                    </ul>
                </div>
                <div className={'cancer-subtitle'}>
                    The Problem
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is a leading cause of death worldwide, accounting for an estimated 9.6 million deaths in 2018. The most common cancers are:
                    <ul>
                        <li className={'cancer-bullet'}>Lung (2.09 million cases)</li>
                        <li className={'cancer-bullet'}>Breast (2.09 million cases)</li>
                        <li className={'cancer-bullet'}>Colorectal (1.80 million cases)</li>
                        <li className={'cancer-bullet'}>Prostate (1.28 million cases)</li>
                        <li className={'cancer-bullet'}>Skin cancer (non-melanoma) (1.04 million cases)</li>
                        <li className={'cancer-bullet'}>Stomach (1.03 million cases)</li>
                    </ul>
                    The most common causes of cancer death are cancers of:
                    <ul>
                        <li className={'cancer-bullet'}>Lung (1.76 million deaths)</li>
                        <li className={'cancer-bullet'}>Colorectal (862 000 deaths)</li>
                        <li className={'cancer-bullet'}>Stomach (783 000 deaths)</li>
                        <li className={'cancer-bullet'}>Liver (782 000 deaths)</li>
                        <li className={'cancer-bullet'}>Breast (627 000 deaths)</li>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    After observing all these alarming stats, as we know that Cancer can strike anyone from any walk of life, let’s be alert and get ourselves educated about the What, Why & How of this dangerous disease & take control of our health with the right Prevention!
                </div>
                <div className={'cancer-subtitle'}>
                    What is Cancer?
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is a generic term for a large group of diseases that can affect any part of the body. Other terms used are malignant tumors and neoplasms. One defining feature of cancer is the rapid creation of abnormal cells that grow beyond their usual boundaries, and which can then invade adjoining parts of the body and spread to other organs, the latter process is referred to as metastasizing. Metastases are a major cause of death from cancer.
                </div>
                <div className={'cancer-subtitle'}>
                    How Cancer Arises
                </div>
                <div className={'container'}>
                    <img src={cancer1}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is caused by certain changes to genes, the basic physical units of inheritance. Genes are arranged in long strands of tightly packed DNA called chromosomes.
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is a genetic disease—that is, it is caused by changes to genes that control the way our cells function, especially how they grow and divide.
                </div>
                <div className={'cancer-paragraph'}>
                    Genetic changes that cause cancer can be inherited from our parents. They can also arise during a person’s lifetime as a result of errors that occur as cells divide or because of damage to DNA caused by certain environmental exposures. Cancer-causing environmental exposures include substances, such as the chemicals in tobacco smoke, and radiation, such as ultraviolet rays from the sun.
                </div>
                <div className={'cancer-paragraph'}>
                    Each person’s cancer has a unique combination of genetic changes. As the cancer continues to grow, additional changes will occur. Even within the same tumor, different cells may have different genetic changes.
                </div>
                <div className={'cancer-paragraph'}>
                    In general, cancer cells have more genetic changes, such as mutations in DNA, than normal cells. Some of these changes may have nothing to do with the cancer; they may be the result of the cancer, rather than its cause.
                </div>
                <div className={'cancer-subtitle'}>Fundamentals of Cancer</div>
                <div className={'cancer-paragraph'}>
                    Cancer cells can break away from the original tumor and travel through the blood or lymph system to distant locations in the body, where they exit the vessels to form additional tumors. This is called metastasis.
                </div>
                <div className={'cancer-paragraph'}>
                    The steps below can give a glance on the formation of this complicated disease.
                </div>
                <div className={'container'}>
                    <img src={cancer2}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is a disease caused when cells divide uncontrollably and spread into surrounding tissues.
                </div>
                <div className={'container'}>
                    <img src={cancer3}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is caused by changes to DNA. Most cancer-causing DNA changes occur in sections of DNA called genes. These changes are also called genetic changes.
                </div>
                <div className={'container'}>
                    <img src={cancer4}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    A DNA change can cause genes involved in normal cell growth to become oncogenes. Unlike normal genes, oncogenes cannot be turned off, so they cause uncontrolled cell growth.
                </div>
                <div className={'container'}>
                    <img src={cancer5}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    In normal cells, tumor suppressor genes prevent cancer by slowing or stopping cell growth. DNA changes that inactivate tumor suppressor genes can lead to uncontrolled cell growth and cancer.
                </div>
                <div className={'container'}>
                    <img src={cancer6}  alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Within a tumor, cancer cells are surrounded by a variety of immune cells, fibroblasts, molecules, and blood vessels—what’s known as the tumor microenvironment. Cancer cells can change the microenvironment, which in turn can affect how cancer grows and spreads.
                </div>
                <div className={'container'}>
                    <img src={cancer7} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Immune system cells can detect and attack cancer cells. But some cancer cells can avoid detection or thwart an attack. Some cancer treatments can help the immune system better detect and kill cancer cells.
                </div>
                <div className={'container'}>
                    <img src={cancer8} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Each person’s cancer has a unique combination of genetic changes. Specific genetic changes may make a person’s cancer more or less likely to respond to certain treatments.
                </div>
                <div className={'container'}>
                    <img src={cancer9} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Genetic changes that cause cancer can be inherited or arise from certain environmental exposures. Genetic changes can also happen because of errors that occur as cells divide.
                </div>
                <div className={'container'}>
                    <img src={cancer10} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Most often, cancer-causing genetic changes accumulate slowly as a person ages, leading to a higher risk of cancer later in life.
                </div>
                <div className={'container'}>
                    <img src={cancer11} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer cells can break away from the original tumor and travel through the blood or lymph system to distant locations in the body, where they exit the vessels to form additional tumors. This is called metastasis.
                </div>
                <div className={'cancer-subtitle'}>
                    Tissue Changes that Are Not Cancer
                </div>
                <div className={'cancer-paragraph'}>
                    Not every change in the body’s tissues is cancer. Some tissue changes may develop into cancer if they are not treated, however. Here are some examples of tissue changes that are not cancer but, in some cases, are monitored:
                </div>
                <div className={'cancer-paragraph'}>
                    Hyperplasia occurs when cells within a tissue divide faster than normal and extra cells build up, or proliferate. However, the cells and the way the tissue is organized look normal under a microscope. Hyperplasia can be caused by several factors or conditions, including chronic irritation.
                </div>
                <div className={'cancer-paragraph'}>
                    Dysplasia is a more serious condition than hyperplasia. In dysplasia, there is also a buildup of extra cells. But the cells look abnormal and there are changes in how the tissue is organized. In general, the more abnormal the cells and tissue look, the greater the chance that cancer will form.
                </div>
                <div className={'cancer-paragraph'}>
                    Some types of dysplasia may need to be monitored or treated. An example of dysplasia is an abnormal mole (called a dysplastic nevus) that forms on the skin. A dysplastic nevus can turn into melanoma, although most do not.
                </div>
                <div className={'cancer-paragraph'}>
                    An even more serious condition is carcinoma in situ. Although it is sometimes called cancer, carcinoma in situ is not cancer because the abnormal cells do not spread beyond the original tissue. That is, they do not invade nearby tissue the way that cancer cells do. But, because some carcinomas in situ may become cancer, they are usually treated.
                </div>
                <div className={'container'}>
                    <img src={cancer12} alt="..."/>
                </div>
                <div className={'cancer-paragraph'}>
                    Normal cells may become cancer cells. Before cancer cells form in tissues of the body, the cells go through abnormal changes called hyperplasia and dysplasia. In hyperplasia, there is an increase in the number of cells in an organ or tissue that appear normal under a microscope. In dysplasia, the cells look abnormal under a microscope but are not cancer. Hyperplasia and dysplasia may or may not become cancer.
                </div>
                <div className={'cancer-subtitle'}>How to prevent Cancer</div>
                <div className={'cancer-sub-subtitle'}>What is Prevention?</div>
                <div className={'cancer-paragraph'}>
                    Cancer prevention is action taken to lower the chance of getting cancer. In 2020, more than 1.8 million people will be diagnosed with cancer in the United States. In addition to the physical problems and emotional distress caused by cancer, the high costs of care are also a burden to patients, their families, and to the public. By preventing cancer, the number of new cases of cancer is lowered. Hopefully, this will reduce the burden of cancer and lower the number of deaths caused by cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    Cancer is not a single disease but a group of related diseases. Many things in our genes, our lifestyle, and the environment around us may increase or decrease our risk of getting cancer. Scientists are studying many different ways to help prevent cancer, including the following:
                    <ul>
                        <li className={'cancer-bullet'}>Ways to avoid or control things known to cause cancer.</li>
                        <li className={'cancer-bullet'}>Changes in <a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/diet">diet</a> and lifestyle.</li>
                        <li className={'cancer-bullet'}>Finding <a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/precancerous">precancerous conditions</a> early. Precancerous conditions are conditions that may become cancer.</li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/chemoprevention">Chemoprevention</a> (<a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/medicine">medicines</a> to treat a precancerous condition or to keep cancer from starting).</li>
                        <li className={'cancer-bullet'}>Risk-reducing <a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/surgery">surgery</a>.</li>
                    </ul>
                </div>
                <div className={'cancer-subtitle'}>Carcinogenesis</div>
                <div className={'cancer-sub-subtitle'}>Key Points</div>
                <div className={'cancer-paragraph'}>
                    <ul>
                        <li className={'cancer-bullet'}>Carcinogenesis is the process in which normal cells turn into cancer cells.</li>
                        <li className={'cancer-bullet'}>Changes (mutations) in genes occur during carcinogenesis.</li>
                    </ul>
                    Carcinogenesis is the series of steps that take place as a normal cell becomes a cancer cell. Cells are the smallest units of the body and they make up the body’s tissues. Each cell contains genes that guide the way the body grows, develops, and repairs itself. There are many genes that control whether a cell lives or dies, divides (multiplies), or takes on special functions, such as becoming a nerve cell or a muscle cell.
                </div>
                <div className={'cancer-sub-subtitle'}>Changes (mutations) in genes occur during carcinogenesis.</div>
                <div className={'cancer-paragraph'}>
                    Changes (mutations) in genes can cause normal controls in cells to break down. When this happens, cells do not die when they should and new cells are produced when the body does not need them. The buildup of extra cells may cause a mass (tumor) to form.
                </div>
                <div className={'cancer-paragraph'}>
                    Tumors can be benign or malignant (cancerous). Malignant tumor cells invade nearby tissues and spread to other parts of the body. Benign tumor cells do not invade nearby tissues or spread.
                </div>
                <div className={'cancer-subtitle'}>Risk Factors</div>
                <div className={'cancer-sub-subtitle'}>Key Points</div>
                <div className={'cancer-paragraph'}>
                    <ul>
                        <li className={'cancer-bullet'}>Factors That are Known to Increase the Risk of Cancer</li>
                        <ul>
                            <li className={'cancer-bullet'}>Cigarette Smoking and Tobacco Use</li>
                            <li className={'cancer-bullet'}>Infections</li>
                            <li className={'cancer-bullet'}>Radiation</li>
                            <li className={'cancer-bullet'}>Immunosuppressive Medicines After Organ Transplant</li>
                        </ul>
                        <li className={'cancer-bullet'}>Factors That May Affect the Risk of Cancer</li>
                        <ul>
                            <li className={'cancer-bullet'}>Diet</li>
                            <li className={'cancer-bullet'}>Alcohol</li>
                            <li className={'cancer-bullet'}>Physical Activity</li>
                            <li className={'cancer-bullet'}>Obesity</li>
                            <li className={'cancer-bullet'}>Diabetes</li>
                            <li className={'cancer-bullet'}>Environmental Risk Factors</li>
                        </ul>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    Scientists study risk factors and protective factors to find ways to prevent new cancers from starting. Anything that increases your chance of developing cancer is called a cancer risk factor; anything that decreases your chance of developing cancer is called a cancer protective factor.
                </div>
                <div className={'cancer-paragraph'}>
                    Some risk factors for cancer can be avoided, but many cannot. For example, both smoking and inheriting certain genes are risk factors for some types of cancer, but only smoking can be avoided. Risk factors that a person can control are called modifiable risk factors.
                </div>
                <div className={'cancer-paragraph'}>
                    Many other factors in our environment, diet, and lifestyle may cause or prevent cancer. This summary reviews only the major cancer risk factors and protective factors that can be controlled or changed to reduce the risk of cancer. Risk factors that are not described in the summary include certain sexual behaviors, the use of estrogen, and being exposed to certain substances at work or to certain chemicals.
                </div>
                <div className={'cancer-subtitle'}>Factors That are Known to Increase the Risk of Cancer</div>
                <div className={'cancer-sub-subtitle'}>Cigarette Smoking and Tobacco Use</div>
                <div className={'cancer-paragraph'}>
                    Tobacco use is strongly linked to an increased risk for many kinds of cancer. Smoking cigarettes is the leading cause of the following types of cancer:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/acute-myelogenous-leukemia">Acute myelogenous leukemia (AML)</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/bladder-cancer">Bladder cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/cervical-cancer">Cervical cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/esophageal-cancer">Esophageal cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/kidney-cancer">Kidney cancer</a>Diabetes</li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/lung-cancer">Lung cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/oral-cavity-cancer">Oral Cavity cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/pancreatic-cancer">Pancreatic cancer</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/stomach-cancer">Stomach cancer</a></li>
                    </ul> 
                    Not smoking or quitting smoking lowers the risk of getting cancer and dying from cancer. Scientists believe that cigarette smoking causes about 30% of all cancer deaths in the United States. See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/lung/patient/lung-prevention-pdq">Lung Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/about-cancer/causes-prevention/risk/tobacco/quit-smoking-pdq">Cigarette Smoking: Health Risks and How to Quit</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Infections</div>
                <div className={'cancer-paragraph'}>
                    Certain viruses and bacteria are able to cause cancer. Viruses and other infection-causing agents cause more cases of cancer in the developing world (about 1 in 4 cases of cancer) than in developed nations (less than 1 in 10 cases of cancer). Examples of cancer-causing viruses and bacteria include:
                    <ul>
                        <li className={'cancer-bullet'}>Human papillomavirus (HPV) increases the risk for cancers of the cervix, penis, vagina, anus, and oropharynx.</li>
                        <li className={'cancer-bullet'}>Hepatitis B and hepatitis C viruses increase the risk for liver cancer.</li>
                        <li className={'cancer-bullet'}>Epstein-Barr virus increases the risk for Burkitt lymphoma.</li>
                        <li className={'cancer-bullet'}>Helicobacter pylori increases the risk for gastric cancer.</li>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    Two vaccines to prevent infection by cancer-causing agents have already been developed and approved by the U.S. Food and Drug Administration (FDA). One is a vaccine to prevent infection with hepatitis B virus. The other protects against infection with strains of human papillomavirus (HPV) that cause cervical cancer. Scientists continue to work on vaccines against infections that cause cancer. See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/cervical/patient/cervical-prevention-pdq">Cervical Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/liver/patient/liver-prevention-pdq">Liver (Hepatocellular) Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/stomach/patient/stomach-prevention-pdq">Stomach (Gastric) Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/head-and-neck/patient/oral-prevention-pdq">Oral Cavity, Pharyngeal, and Laryngeal Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Radiation</div>
                <div className={'cancer-paragraph'}>
                    Being exposed to radiation is a known cause of cancer. There are two main types of radiation linked with an increased risk for cancer:
                    <ul>
                        <li className={'cancer-bullet'}>Ultraviolet radiation from sunlight: This is the main cause of nonmelanoma skin cancers.</li>
                        <li className={'cancer-bullet'}>Ionizing radiation including:</li>
                        <ul>
                            <li className={'cancer-bullet'}>Medical radiation from tests to diagnose cancer such as x-rays, CT scans, fluoroscopy, and nuclear medicine scans.</li>
                            <li className={'cancer-bullet'}>Radon gas in our homes.</li>
                        </ul>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    Scientists believe that ionizing radiation causes leukemia, thyroid cancer, and breast cancer in women. Ionizing radiation may also be linked to myeloma and cancers of the lung, stomach, colon, esophagus, bladder, and ovary. Being exposed to radiation from diagnostic x-rays increases the risk of cancer in patients and x-ray technicians. Diagnostic radiation in children and adolescents has been linked with a higher risk of cancers at a young age.
                </div>
                <div className={'cancer-paragraph'}>
                    The growing use of CT scans over the last 20 years has increased exposure to ionizing radiation. The risk of cancer also increases with the number of CT scans a patient has and the radiation dose used each time.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/skin/patient/skin-prevention-pdq">Skin Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Immunosuppressive Medicines After Organ Transplant</div>
                <div className={'cancer-paragraph'}>
                    Immunosuppressive medicines are used after an organ has been transplanted from one person to another. These medicines stop an organ that has been transplanted from being rejected. These medicines decrease the body’s immune response to help keep the organ from being rejected. Immunosuppressive medicines are linked to an increased risk of cancer because they lower the body’s ability to keep cancer from forming. The risk of cancer, especially cancer caused by a virus, is higher in the first 6 months after organ transplant, but the risk lasts for many years.
                </div>
                <div className={'cancer-subtitle'}>Factors That May Affect the Risk of Cancer</div>
                <div className={'cancer-sub-subtitle'}>Diet</div>
                <div className={'cancer-paragraph'}>
                    The foods that you eat on a regular basis make up your diet. Diet is being studied as a risk factor for cancer. It is hard to study the effects of diet on cancer because a person’s diet includes foods that may protect against cancer and foods that may increase the risk of cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    It is also hard for people who take part in the studies to keep track of what they eat over a long period of time. This may explain why studies have different results about how diet affects the risk of cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    Some studies have shown that a diet high in fat, proteins, calories, and red meat increases the risk of colorectal cancer, but other studies have not shown this.
It is not known if a diet low in fat and high in fiber, fruits, and vegetables lowers the risk of colorectal cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Alcohol</div>
                <div className={'cancer-paragraph'}>
                    Studies have shown that drinking alcohol is linked to an increased risk of the following types of cancers:
                    <ul>
                        <li className={'cancer-bullet'}>Oral cancer</li>
                        <li className={'cancer-bullet'}>Esophageal cancer</li>
                        <li className={'cancer-bullet'}>Breast cancer</li>
                        <li className={'cancer-bullet'}>Colorectal cancer (in men)</li>
                    </ul>
                    Drinking alcohol may also increase the risk of liver cancer and female colorectal cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/esophageal/patient/esophageal-prevention-pdq">Esophageal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/head-and-neck/patient/oral-prevention-pdq">Oral Cavity, Pharyngeal, and Laryngeal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/liver/patient/liver-prevention-pdq">Liver (Hepatocellular) Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Physical Activity</div>
                <div className={'cancer-paragraph'}>
                    Studies show that people who are physically active have a lower risk of certain cancers than those who are not. It is not known if physical activity itself is the reason for this.
                </div>
                <div className={'cancer-paragraph'}>
                    Studies show a strong link between physical activity and a lower risk of colorectal cancer. Some studies show that physical activity protects against postmenopausal breast cancer and endometrial cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/uterine/patient/endometrial-prevention-pdq">Endometrial Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Obesity</div>
                <div className={'cancer-paragraph'}>
                    Studies show that obesity is linked to a higher risk of the following types of cancer:
                    <ul>
                        <li className={'cancer-bullet'}>Postmenopausal breast cancer</li>
                        <li className={'cancer-bullet'}>Colorectal cancer</li>
                        <li className={'cancer-bullet'}>Endometrial cancer</li>
                        <li className={'cancer-bullet'}>Esophageal cancer</li>
                        <li className={'cancer-bullet'}>Kidney cancer</li>
                        <li className={'cancer-bullet'}>Pancreatic cancer</li>
                    </ul>
                    Some studies show that obesity is also a risk factor for cancer of the gallbladder and liver cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    It is not known if losing weight lowers the risk of cancers that have been linked to obesity.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/uterine/patient/endometrial-prevention-pdq">Endometrial Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/lung/patient/lung-prevention-pdq">Lung Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-sub-subtitle'}>Diabetes</div>
                <div className={'cancer-paragraph'}>
                    Some studies show that having diabetes may slightly increase the risk of having the following types of cancer:
                    <ul>
                        <li className={'cancer-bullet'}>Bladder cancer</li>
                        <li className={'cancer-bullet'}>Breast cancer in women</li>
                        <li className={'cancer-bullet'}>Colorectal cancer</li>
                        <li className={'cancer-bullet'}>Endometrial cancer</li>
                        <li className={'cancer-bullet'}>Liver cancer</li>
                        <li className={'cancer-bullet'}>Lung cancer</li>
                        <li className={'cancer-bullet'}>Oral cancer</li>
                        <li className={'cancer-bullet'}>Oropharyngeal cancer</li>
                        <li className={'cancer-bullet'}>Ovarian cancer</li>
                        <li className={'cancer-bullet'}>Pancreatic cancer</li>
                    </ul>
                    Diabetes and cancer share some of the same risk factors. These risk factors include the following:
                    <ul>
                        <li className={'cancer-bullet'}>Being older</li>
                        <li className={'cancer-bullet'}>Being obese</li>
                        <li className={'cancer-bullet'}>Smoking</li>
                        <li className={'cancer-bullet'}>Not eating a healthy diet</li>
                        <li className={'cancer-bullet'}>Not exercising</li> 
                    </ul>
                    Because diabetes and cancer share these risk factors, it is hard to know whether the risk of cancer is increased more by diabetes or by these risk factors. Studies are being done to see how medicine that is used to treat diabetes affects cancer risk.
                </div>
                <div className={'cancer-sub-subtitle'}>Environmental Risk Factors</div>
                <div className={'cancer-paragraph'}>
                    Being exposed to chemicals and other substances in the environment has been linked to some cancers:
                    <ul>
                        <li className={'cancer-bullet'}>Links between air pollution and cancer risk have been found. These include links between lung cancer and secondhand tobacco smoke, outdoor air pollution, and asbestos.</li>
                        <li className={'cancer-bullet'}>Drinking water that contains a large amount of arsenic has been linked to skin, bladder, and lung cancers.</li>
                    </ul>
                    Studies have been done to see if pesticides and other pollutants increase the risk of cancer. The results of those studies have been unclear because other factors can change the results of the studies.
                </div>
                <div className={'cancer-subtitle'}>
                    Interventions That are Known to Lower Cancer Risk
                </div>
                <div className={'cancer-sub-subtitle'}>Key Points</div>
                <div className={'cancer-paragraph'}>
                    <ul>
                        <li className={'cancer-bullet'}>Chemoprevention is being studied in patients who have a high risk of developing cancer.</li>
                    </ul>
                    An intervention is a treatment or action taken to prevent or treat disease, or improve health in other ways. Many studies are being done to find ways to keep cancer from starting or recurring (coming back).
                </div>
                <div className={'cancer-sub-subtitle'}>Chemoprevention is being studied in patients who have a high risk of developing cancer.</div>
                <div className={'cancer-paragraph'}>
                    Chemoprevention is the use of substances to lower the risk of cancer, or keep it from recurring. The substances may be natural or made in the laboratory. Some chemopreventive agents are tested in people who are at high risk for a certain type of cancer. The risk may be because of a precancerous condition, family history, or lifestyle factors.
                </div>
                <div className={'cancer-paragraph'}>
                    Taking one of the following agents may lower the risk of cancer:
                    <ul>
                        <li className={'cancer-bullet'}>Selective estrogen receptor modulators (SERMS) such as tamoxifen or raloxifene have been shown to reduce the risk of breast cancer in women at high risk. SERMS may cause side effects, such as hot flashes, so they are not often used for prevention of cancer. See the PDQ summary on <a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a> for more information.</li>
                        <li className={'cancer-bullet'}>Finasteride has been shown to lower the risk of prostate cancer. See the PDQ summary on <a href="https://www.cancer.gov/types/prostate/patient/prostate-prevention-pdq">Prostate Cancer Prevention</a> for more information.</li>
                        <li className={'cancer-bullet'}>COX-2 inhibitors may prevent colon and breast cancer. COX-2 inhibitors may cause heart problems. Because COX-2 inhibitors may cause heart problems there have not been many studies on their use to prevent cancer. See the PDQ summaries on <a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a> and <a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a> for more information.</li>
                        <li className={'cancer-bullet'}>Aspirin may prevent colorectal cancer. Bleeding in the gastrointestinal tract or brain is a side effect of aspirin. Because aspirin may cause bleeding problems there have not been many studies on its use to prevent cancer. See the PDQ summary on <a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a> for more information.</li>
                    </ul>
                </div>
                <div className={'cancer-paragraph'}>
                    See the <a href="https://www.cancer.gov/about-cancer/causes-prevention">NCI website</a> for more information about cancer prevention.
                </div>
                <div className={'cancer-subtitle'}>
                    Interventions That Are Not Known to Lower Cancer Risk
                </div>
                <div className={'cancer-sub-subtitle'}>
                    Key Points
                </div>
                <div className={'cancer-paragraph'}>
                    <ul>
                        <li className={'cancer-bullet'}>Vitamin and dietary supplements have not been shown to prevent cancer.</li>
                        <li className={'cancer-bullet'}>New ways to prevent cancer are being studied in clinical trials.</li>
                    </ul> 
                </div>
                <div className={'cancer-sub-subtitle'}>
                    Vitamin and dietary supplements have not been shown to prevent cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    An intervention is a treatment or action taken to prevent or treat disease, or improve health in other ways. There is not enough proof that taking multivitamin and mineral supplements or single vitamins or minerals can prevent cancer. The following vitamins and mineral supplements have been studied, but have not been shown to lower the risk of cancer:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/vitamin-b6">Vitamin B6</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/vitamin-b12">Vitamin B12</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/vitamin-e">Vitamin E</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/vitamin-c">Vitamin C</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/beta-carotene">Beta carotene</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/folic-acid">Folic acid</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/selenium">Selenium</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/publications/dictionaries/cancer-terms/def/vitamin-d">Vitamin D</a></li>
                    </ul>
                    The Selenium and Vitamin E Cancer Prevention Trial (SELECT) found that vitamin E taken alone increased the risk of prostate cancer. The risk continued even after the men stopped taking vitamin E. Taking selenium with vitamin E or taking selenium alone did not increase the risk of prostate cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    Vitamin D has also been studied to see if it has anticancer effects. Skin exposed to sunshine can make vitamin D. Vitamin D can also be consumed in the diet and in dietary supplements. Taking vitamin D in doses from 400-1100 IU/ day has not been shown to lower or increase the risk of cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    The Vitamin D and Omega-3 Trial (VITAL) is under way to study whether taking vitamin D (2000 IU/ day) and omega-3 fatty acids from marine (oily fish) sources lowers the risk of cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    The Physicians' Health Study found that men who have had cancer in the past and take a multivitamin daily may have a slightly lower risk of having a second cancer.
                </div>
                <div className={'cancer-paragraph'}>
                    See the following PDQ summaries for more information:
                    <ul>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/breast/patient/breast-prevention-pdq">Breast Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/colorectal/patient/colorectal-prevention-pdq">Colorectal Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/lung/patient/lung-prevention-pdq">Lung Cancer Prevention</a></li>
                        <li className={'cancer-bullet'}><a href="https://www.cancer.gov/types/prostate/patient/prostate-prevention-pdq">Prostate Cancer Prevention</a></li>
                    </ul>
                </div>
                <div className={'cancer-subtitle'}>
                    Conclusion
                </div>
                <div className={'cancer-sub-subtitle'}>
                    New ways to prevent cancer are being studied in clinical trials.
                </div>
                <div className={'cancer-paragraph'}>
                    Information about clinical trials supported by NCI can be found on NCI’s <a href="https://www.cancer.gov/about-cancer/treatment/clinical-trials/search">clinical trials search</a> webpage. Clinical trials supported by other organizations can be found on the <a href="https://clinicaltrials.gov/">ClinicalTrials.gov</a> website.
                </div>
                <div className={'cancer-paragraph'}>
                    It’s always good to continue the normal diet/ nutrition, exercise, lifestyle & stress minimization techniques for maintaining optimal health throughout one's lifetime;there is a lot of wisdom in the old saying : Prevention is always better than cure!!
                </div>
                <div className={'cancer-sub-subtitle-alt3'}>
                    Please Share your feedback at email: contact.naturomind@gmail.com
                </div>
            </div>
        );
    }
};